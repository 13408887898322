<template>
  <v-container fluid>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="7">
        <h1>Validar Dirección de Entrega</h1>
      </v-col>

      <v-col style="position: realtive" cols="12" sm="12" md="12">
        <div class="searchGeo">
          <i class="far fa-search" :loading="searching" />
          <input
            type="text"
            v-model="searchTerm"
            v-on:keyup="geoQuerySearch()"
            v-on:keyup.escape="searchResults = []"
            placeholder="Busca una dirección o ingresa las coordenadas"
          />
          <transition name="slide" appear>
            <div v-if="searchResults.length" class="matches">
              <ul>
                <li
                  v-for="result in searchResults"
                  :key="result.properties.osm_id"
                  @click="geoQuerySelect(result)"
                >
                  <i class="fas fa-map-marker-alt"></i> {{ result.name }}
                </li>
              </ul>
            </div>
          </transition>
        </div>

        <div class="coordinates">
          {{ center.lat.toFixed(7) }}, {{ center.lng.toFixed(7) }}
        </div>

        <l-map
          @update:center="centerUpdated"
          ref="map"
          :zoom="zoom"
          :center="center"
          class="map"
        >
          <l-tile-layer :url="url" :attribution="attribution" />

          <l-marker
            @update:lat-lng="centerUpdated"
            :draggable="true"
            :lat-lng="center"
            :icon="getImage"
          >
          </l-marker>
        </l-map>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import L from "leaflet";
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css";

import {
  LMap,
  LTileLayer,
  LControlZoom,
  LMarker,
  LPopup,
  LTooltip,
} from "vue2-leaflet";
require("leaflet-routing-machine");

require("leaflet-control-geocoder");

export default {
  name: "zone-validation",
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker,
    LPopup,
    LTooltip,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      //    globals: null,
      //   showDetailsPanel: true,
      //   myLocationMarket: null,
      //   pointToEdit: {},
      //   selectedPointToEdit: null,
      //   editPointDialog: false,
      //   version: process.env.VUE_APP_VERSION,
      //   business: null,
      //   showDetails: false,
      //   to: null,
      //   card: null,
      //   position: null,
      searchTerm: null,
      searchResults: [],
      useGoogleSearch: false,
      //   points: [],
      polygons: [],
      searching: true,
      //   exitDialog: false,
      //   selectedStartingPoint: null,
      //   selectedDestinationPoint: null,
      //   activeButton: true,
      //   selectedPoint: null,
      //   currentZone: null,
      //   generating: false,
      zoom: 15,
      center: latLng(14.075104529559956, -87.19571461307869),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      showParagraph: false,
      map: null,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      routeControl: "",
      geoCoder: null,
    };
  },

  computed: {
    getImage() {
      return L.icon({
        iconUrl: require("@/assets/user.svg"),
        shadowUrl: require("@/assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
  },

  methods: {
    async init() {
      await this.$binding("globals", db.collection(`settings`).doc("global"))
        .then(async (globals) => {
          let geoJson = JSON.parse(globals.coverageZone);
          let geoJsonLayer = L.geoJSON(geoJson, {
            style: function (feature) {
              return {
                color: "#ff0000", //feature.properties.color,
                weight: 0,
                opacity: 1,
                fillOpacity: 0.1,
              };
            },
          }).addTo(this.map);
          geoJsonLayer.eachLayer((layer) => {
            // if (layer.feature.properties.color != "transparent") {
            this.polygons.push(layer);
            // }
            layer._path.id = `${layer.feature.properties.city}`;
            layer._path.zone = layer.feature.properties.name;
            layer._path.classList.add("geofence");
          });

          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
          }
          this.$store.commit("setLoading", false);
        })
        .catch((err) => {
          // // console.log("geoJson Error", err);
        });
    },
    centerUpdated(e) {
      this.center = e;
    },
    geoQuerySearch() {
      this.searchResults = [];
      if (this.searchTerm) {
        var re =
          /[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/;
        let result = re.exec(this.searchTerm);

        if (result && result.length) {
          this.geoCoder.setQuery(result[0]);
          this.geoCoder._geocode();
        } else {
          this.geoCoder.setQuery(`Honduras, tegucigalpa,${this.searchTerm}`);
          this.geoCoder._geocode();
        }
      }
    },

    async geoQuerySelect(selection) {
      //   console.log(selection);
      this.searchResults = [];
      this.searchTerm = "";
      let isValid = await this.checkPoint(latLng(selection.center));

      //   console.log(isValid);

      //   if (isValid) {
      this.center = selection.center;
      // this.waypoints.push(latLng(selection.center));
      // this.redrawMarkers();
      //   }
    },

    checkPoint(point) {
      return new Promise((resolve, reject) => {
        let response = false;
        var x = point.lat;
        var y = point.lng;
        for (let p = 0; p < this.polygons.length; p++) {
          const polygon = this.polygons[p];
          let polyPoints = polygon.getLatLngs();
          polyPoints = polyPoints[0];
          for (
            var i = 0, j = polyPoints.length - 1;
            i < polyPoints.length;
            j = i++
          ) {
            var xi = polyPoints[i].lat;
            var yi = polyPoints[i].lng;
            var xj = polyPoints[j].lat;
            var yj = polyPoints[j].lng;
            var intersect =
              yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) {
              response = !response;
            }
          }
        }
        return resolve(response);
      });
    },
  },

  async mounted() {
    this.$store.state.visibleSearch = false;

    this.map = this.$refs.map.mapObject;

    this.geoCoder = L.Control.geocoder({
      geocoder: this.useGoogleSearch
        ? new L.Control.Geocoder.Google(process.env.VUE_APP_MY_WEB_API_KEY)
        : "",
      defaultMarkGeocode: false,
    })
      .on("startgeocode", (e) => {})
      .on("finishgeocode", (e) => {
        if (e.results.length > 0) {
          this.searchResults = e.results;
        }
      })
      .addTo(this.map);

    this.init();
  },
};
</script>


<style scoped lang="scss">
.map {
  position: absolute !important;
  width: 95%;
  height: 70vh;
}

.coordinates {
  background-color: rgba(255, 255, 255, 0.367);
  padding: 7px;
  color: #000000;
  font-size: 20px;
  position: absolute;
  right: 5vw;
  z-index: 9999;
  bottom: 10vh;
  font-weight: bold;
}

.searchGeo {
  appearance: none;
  border: none;
  outline: none;
  font-weight: bold;
  color: white;
  text-decoration: none;
  margin: 0 5px;
  box-sizing: content-box;
  text-align: center;
  width: 500px;
  border-radius: 10px;
  position: absolute;
  top: 15vh;
  left: 50%;
  margin-left: -250px;
  transition: 0.4s 0s all cubic-bezier(0.44, 0.1, 0.28, 1.59);
  color: white;
  z-index: 99999;
  &::after {
    // content: "Presiona ↵ para buscar";
    position: absolute;
    z-index: 9999;
    color: #FF1744;
    text-align: right;
    font-size: 11px;
    line-height: 10px;
    width: 80px;
    top: 11px;
    right: 16px;
    opacity: 0;
    transition: 0.3s all;
  }
  &:hover::after {
    opacity: 1;
  }
  input {
    appearance: none;
    border: none;
    outline: none;
    padding: 5px 40px;
    padding-left: 40px;
    padding-right: 90px;
    background: none;
    font-size: 14px;
    font-weight: 500;
    flex: 1;
    box-sizing: border-box;
    height: 100%;
    height: 42px;
    width: 100%;
    opacity: 0.9;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 20px rgba(#000000, 0.3));
    background-color: #fff;
    transition: 0.3s all;
    color: #666;
    &:focus,
    &:hover {
      color: #111;
      filter: drop-shadow(0px 0px 20px rgba(#000000, 0.4));
      opacity: 1;
    }
    &::placeholder {
      color: #333;
      opacity: 0.6;
    }
  }
  i {
    top: 10px;
    left: 10px;
    position: absolute;
    color: #FF1744;
    z-index: 1000;
    font-size: 21px;
    margin-right: 10px;
  }
  .matches {
    width: 100%;
    box-sizing: border-box;
    max-height: 50vh;
    overflow: hidden;
    color: #111;
    text-align: left;
    font-weight: normal;
    margin: 10px 0;
    background-color: white;
    border-left: 5px solid white;
    border-right: 5px solid white;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 20px rgba(#000000, 0.3));
    ul {
      list-style: none;
      max-height: 50vh;
      overflow: hidden;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 1px;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #FF1744;
        border-radius: 10px;
        height: 10px;
      }
      li {
        padding: 5px 10px;
        padding-bottom: 10px;
        margin: 5px;
        font-size: 14px;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        cursor: pointer;
        i {
          color: #111;
          position: relative;
          top: 0;
          left: 0;
          margin-right: 5px;
          font-size: 16px;
          opacity: 0.2;
        }
        &:hover {
          color: #FF1744 !important;
          i {
            opacity: 1;
            color: #FF1744 !important;
          }
        }
        &:first-of-type {
          padding-top: 0px;
          margin-top: 20px;
        }
        &:last-of-type {
          padding-bottom: 0px;
          border-bottom: none;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>